import { email } from 'vee-validate/dist/rules';
import Component from 'vue-class-component';
import Vue from 'vue';
import metaDataModule from '@/store/modules/metaDataModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import workflowModule from '@/store/modules/workflowModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
import assetsModule from '@/store/modules/assetsModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import accountModule from '@/store/modules/accountModule';
import gptModule from '@/store/modules/gptModule';

@Component
export default class TasqFeedbackMixin extends Vue {
  isSavingData = false;

  showLinkSharePopup = false;

  get feedbackStepNo() {
    return tasqFeedbackModule.stepper;
  }

  get currentViewType() {
    return this.$route.query.view;
  }

  get tasqListLevel() {
    return tasqsListModule.tasqListLevel.toLowerCase();
  }

  get producingTasq() {
    return assetsModule.activeTasq;
  }

  get currentWellType(): any {
    if (this.producingTasq) {
      return 'producing';
    }
    return this.$route.query.type;
  }

  get selectedForms() {
    return tasqFeedbackModule.selectedForms;
  }

  get tasq() {
    if (tasqsListModule.isBatchResponding) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }
    if (tasqsListModule.activeTasq) {
      return tasqsListModule.activeTasq as TasqJob;
    }
    // if (this.currentWellType === 'producing') {
    //   console.log( assetsModule.activeTasq);

    // }
    return assetsModule.activeTasq as TasqJob;
  }

  copyLink(type, wellName = '', tasqListLevel: any = 'tasq') {
    if (type === 'tasq') {
      navigator.clipboard.writeText(window.location.href);
    } else if (type === 'well') {
      const url = `${window.location.origin}/Tasqs/${encodeURI(
        wellName
      )}?type=producing&view=wellview`;
      navigator.clipboard.writeText(url);
    } else if (type === 'pad') {
      const url = `${window.location.origin}/Tasqs/${encodeURI(wellName)}?&view=padview`;
      navigator.clipboard.writeText(url);
    }

    this.showLinkSharePopup = false;
  }

  async handleFormResponse({ markAsComplete = false, isBatchMode = false, isGPTView= false }) {
    this.isSavingData = true;
    try {
      const jobFormResponsePayload: any = {
        predictionID: this.tasq.id,
        workflowTaskID: this.tasq.workflowTasqId,
        completeForToday: markAsComplete,
        closeTasq: this.tasq?.isManuallyAdded && markAsComplete ? true : false,
        formResponse: tasqFeedbackModule.selectedForms,
        nodeID: this.tasq.wellName,
        validationResponse: tasqFeedbackModule.validationResponse,
      };
      if (this.tasq.predictionType === 'Producing' || this.tasq.level === 'PAD') {

        await this.postProducingWellForms();
      } else if (isBatchMode) {
        await this.postBatchTasqForms(jobFormResponsePayload);
        if (!markAsComplete) {
          tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
        } else {
          tasqsListModule.setActiveTasq('');
          tasqsListModule.setIsEditing(false);
          tasqsListModule.resetCheckedTasqs();
          tasqFeedbackModule.resetAllData();
          this.$tasqAlert({
            title: 'Success',
            message: 'All batch responses saved successfully.',
            type: 'success',
          });
          const currentName: any = this.$route.name;
          this.$router.push({ name: currentName });
        }
      } else {
        await this.postTasqForms(jobFormResponsePayload);
        if(isGPTView){
          if(this.feedbackStepNo < 2){
            tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
          }
        }else{
          tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
        }

      }
      await tasqsListModule.getSubmittedForms()
    } catch (error) {
      this.$tasqAlert({
        title: 'Error',
        message: 'Saving actions failed!',
        type: 'error',
      });
    }
    this.isSavingData = false;
  }

  async postProducingWellForms() {
    if (this.feedbackStepNo === 1) {
      tasqFeedbackModule.setStepper(this.feedbackStepNo + 1);
    } else if (this.feedbackStepNo === 2) {
      const uniqueCategories = [
        ...new Set(tasqFeedbackModule.selectedForms.map((form) => form.category)),
      ];
      uniqueCategories.forEach(async (category) => {
        const predictionID = await tasqActionsModule.addTasq({
          // eslint-disable-next-line max-len
          assetType: this.tasq.level,
          person: accountModule.user.email,
          well: this.tasq.wellName,
          comment: '',
          actions: tasqFeedbackModule.selectedForms.filter((form) => form.category === category),
          jobType: category,
        });

        await tasqsListModule.getRawTasq({ PredictionID: predictionID });
      });
      tasqFeedbackModule.resetAllData();
      this.$tasqAlert({
        title: 'Success',
        message: 'Action(s) recorded successfully.',
        type: 'success',
      });
    }
  }

  async postTasqForms(jobFormResponsePayload) {
    await metaDataModule.postJobFormsResponse(jobFormResponsePayload);
    gptModule.setOverviewHidden(true)
    this.$nextTick(() => {
      gptModule.setOverviewHidden(false)
    })

    // await tasqsListModule.getTasq({ PredictionID: this.tasq.id });
    // await this.getWellHistory()
  }

  async getWellHistory() {
    const promises: any = [];

    if (this.tasq.workflowTasqId != null) {
      promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.workflowTasqId));
    } else if (this.tasq.scheduledJobID != null) {
      promises.push(tasqsListModule.getTasqEventHistoryList(this.tasq.scheduledJobID));
    }

    promises.push(workflowModule.getWellHistory(this.tasq.wellName));

    Promise.all(promises).then(
      () => {
        // console.log('resolved handle step v1');
      },
      (err) => {
        // eslint-disable-next-line no-console
        console.log('Error:');
        // eslint-disable-next-line no-console
        console.log(err);
      }
    );
  }

  async postBatchTasqForms(jobFormResponsePayload) {
    for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
      const checkedTasqDetails = tasqsListModule.tasqById(tasqsListModule.checkedTasqs[i]);
      jobFormResponsePayload.predictionID = checkedTasqDetails?.id;
      jobFormResponsePayload.nodeID = checkedTasqDetails?.wellName;
      jobFormResponsePayload.workflowTaskID = checkedTasqDetails?.workflowTasqId;
      jobFormResponsePayload.closeTasq = checkedTasqDetails?.isManuallyAdded;
      const newPayload = { ...jobFormResponsePayload };
      // eslint-disable-next-line no-await-in-loop
      await metaDataModule.postJobFormsResponse(newPayload);
      // eslint-disable-next-line no-await-in-loop
      await tasqsListModule.getRawTasq({
        PredictionID: tasqsListModule.checkedTasqs[i],
      });
    }
  }

  destroyed() {
    tasqFeedbackModule.resetAllData();
  }
}
